import type { UserIdEssential } from "@client/models";

// NOTE: Permissions are in the pattern of [ACTION]_[RESOURCE].
// Adding permissions is as simple as adding to the arrays below or
// adding a new array that is tied to a entity (e.g. control).
export const permissions = {
  step: [
    "CREATE_STEP",
    "EDIT_STEP",
    "DELETE_STEP",
    "LINK_STEP",
    "CHANGE_STEP_OWNER",
  ] as const,
  control: [
    "CREATE_CONTROL",
    "EDIT_CONTROL",
    "DELETE_CONTROL",
    "LINK_CONTROL",
  ] as const,
  jobAid: ["LINK_JOB_AID"] as const,
  spreadsheet: ["SUBMIT_SPREADSHEET"] as const,
  roles: ["ASSIGN_ROLE"] as const,
  settings: ["VIEW_HIERARCHY"] as const,
} as const;
export const allPermissions = Object.values(permissions).flat();
type Permissions = typeof allPermissions;

// NOTE: To add a role, add to the Role array and then add an
// entry to the RolePermissions object with the permissions
// for that role.
export const Role = [
  "admin",
  "security_admin",
  "process_admin",
  "control_admin",
  "user",
] as const;
type RoleType = (typeof Role)[number];

export const RolePermissions: RolePermissionsType = {
  admin: allPermissions,
  process_admin: [
    ...permissions.step,
    ...permissions.control,
    ...permissions.jobAid,
  ],
  control_admin: [...permissions.control],
  security_admin: [...permissions.roles],
  user: ["EDIT_STEP"],
};
type RolePermissionsType = {
  [key in RoleType]: Readonly<Permissions>;
};

const developerEmails = [
  "cbissell@magmutual.com",
  "btedeschi@magmutual.com",
  "pforbes@magmutual.com",
  "dbarnett@magmutual.com",
  "adillahunty@magmutual.com",
  "ttorbett@magmutual.com",
];

const hasPermission = (
  userData: UserIdEssential,
  perms: Readonly<Permissions>,
): boolean => {
  // return false if no user data
  if (!userData) {
    return false;
  }

  // hardcoded bypass for developers
  if (developerEmails.includes(userData.email)) {
    return true;
  }

  for (const perm of perms) {
    if (!RolePermissions[userData.role]?.includes(perm)) {
      return false;
    }
  }
  return true;
};

type PermissionsObjectType = {
  [key: string]: boolean;
};
export const getPermissions = (userData: UserIdEssential) => {
  const result: PermissionsObjectType = {};

  result["canManageSteps"] = hasPermission(userData, permissions.step);
  result["canManageControls"] = hasPermission(userData, permissions.control);
  result["fullAccess"] = hasPermission(userData, allPermissions);

  // Makes key for permission in the form of 'can[PERM]'
  const createKeyForPerm = (perm: string): string => {
    let result = "can";
    let foundUpper = false;

    for (let i = 0; i < perm.length; ++i) {
      let char = perm[i];

      if (char === "_") {
        foundUpper = false;
        continue;
      } else if (char === char.toUpperCase() && !foundUpper) {
        foundUpper = true;
      } else {
        char = char.toLowerCase();
      }

      result += char;
    }

    return result;
  };

  for (const perm of allPermissions) {
    const objKey = createKeyForPerm(perm);
    result[objKey] = hasPermission(userData, [perm]);
  }

  return result;
};
