import { useAuth0 } from "@auth0/auth0-react";
import SplunkOtelWeb from "@splunk/otel-web";
import { AxiosError } from "axios";
import { useAtom } from "jotai";
import { Suspense, lazy, useEffect } from "react";

import apiClient, { setAccessTokenCallback } from "@utils/api";

import { UserData } from "@store/PermissionsAtom";

import { ThemeProvider } from "@components/shadcn/theme-provider";
import Loading from "@components/util/Loading";

import "./App.scss";

const AppInner = lazy(() => import("./AppInner"));
function App() {
  const {
    user,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  const [userData, setUserData] = useAtom(UserData);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const controller = new AbortController();
    const { signal } = controller;

    if (isAuthenticated) {
      setAccessTokenCallback(async () => {
        const audience: string = "https://core-tasks-api.magmutual.com";
        const scope: string = "openid profile email offline_access";
        try {
          const token = await getAccessTokenSilently({
            authorizationParams: { audience, scope },
          });
          return token;
        } catch (e) {
          if (
            e.error === "missing_refresh_token" ||
            e.error === "invalid_grant" ||
            e.error === "login_required"
          ) {
            const loc = window.location;
            await loginWithRedirect({
              appState: {
                returnTo: loc.pathname + loc.search + loc.hash,
              },
            });
          } else {
            throw e;
          }
        }
        return "";
      });
    }
    if (user) {
      let username = user.name;
      if (!username && user.given_name && user.family_name) {
        username = `${user.given_name} ${user.family_name}`;
      }

      // Create user if they don't exist in the db
      const customUserId = user?.sub.split("|")[2];
      apiClient
        .userControllerGetById(customUserId)
        .then((res) => {
          if (!userData) {
            setUserData(res.data);
          }
        })
        .catch((e: AxiosError) => {
          if (signal.aborted) return;
          if (e.response.status === 404) {
            const userObj = {
              id: customUserId,
              email: user.email.toLowerCase(),
              role: "user",
            };
            apiClient
              .userControllerCreateEntity(userObj)
              .then(() => setUserData(userObj))
              .catch((err: AxiosError) => console.error(err));
          }
        });

      username = username || user.nickname || undefined;
      SplunkOtelWeb.setGlobalAttributes({
        sub: user.sub,
        email: user.email,
        name: username,
      });
    }

    return () => controller.abort();
  }, [isLoading, isAuthenticated, user, getAccessTokenSilently]);

  return (
    <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
      <Suspense fallback={<Loading />}>
        <AppInner />
      </Suspense>
    </ThemeProvider>
  );
}
App.whyDidYouRender = true;

export default App;
