import { RingLoader } from "react-spinners";

export default function Loading() {
  return (
    <div className="h-full content-center justify-center">
      <RingLoader className="m-auto" color="#0f62fe" />
    </div>
  );
}

export function LoadingModal() {
  return (
    <dialog
      open
      className="animate-in fade-in fill-mode-forwards absolute top-0 left-0 z-10 flex h-full w-full items-center justify-center bg-gray-700/50 duration-500"
    >
      <RingLoader color="#0f62fe" />
    </dialog>
  );
}
