import { atom } from "jotai";

import type { UserIdEssential } from "@client/models";

import { getPermissions } from "@data/permissions";

export const UserData = atom<UserIdEssential>();

// NOTE: All permissions can be obtained from this atom
// which returns an object that contains key in the pattern
// of can[Permission] (e.g. canCreateStep). The only exception
// to this is for Full Access which is just 'fullAccess'.
export const Permissions = atom((get) => getPermissions(get(UserData)));
