/* tslint:disable */
/* eslint-disable */
/**
 * Core Tasks API
 * MagMutual Core Tasks API
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AdditionalOptions } from '../models';
// @ts-ignore
import type { AdditionalOptionsEssential } from '../models';
// @ts-ignore
import type { AdditionalOptionsIdEssential } from '../models';
// @ts-ignore
import type { Auth0UserDTO } from '../models';
// @ts-ignore
import type { Control } from '../models';
// @ts-ignore
import type { ControlAttestation } from '../models';
// @ts-ignore
import type { ControlAttestationDocument } from '../models';
// @ts-ignore
import type { ControlAttestationDocumentEssential } from '../models';
// @ts-ignore
import type { ControlAttestationDocumentIdEssential } from '../models';
// @ts-ignore
import type { ControlAttestationEssential } from '../models';
// @ts-ignore
import type { ControlAttestationIdEssential } from '../models';
// @ts-ignore
import type { ControlAttestationStepRevision } from '../models';
// @ts-ignore
import type { ControlAttestationStepRevisionEssential } from '../models';
// @ts-ignore
import type { ControlAttestationStepRevisionIdEssential } from '../models';
// @ts-ignore
import type { ControlIdEssential } from '../models';
// @ts-ignore
import type { ControlRevision } from '../models';
// @ts-ignore
import type { ControlRevisionEssential } from '../models';
// @ts-ignore
import type { ControlRevisionIdEssential } from '../models';
// @ts-ignore
import type { ControlStep } from '../models';
// @ts-ignore
import type { ControlStepEssential } from '../models';
// @ts-ignore
import type { ControlStepIdEssential } from '../models';
// @ts-ignore
import type { EmailParams } from '../models';
// @ts-ignore
import type { EmployeeData } from '../models';
// @ts-ignore
import type { EmployeeLayoutResult } from '../models';
// @ts-ignore
import type { FilterDTO } from '../models';
// @ts-ignore
import type { FindByColumnQueryUUID } from '../models';
// @ts-ignore
import type { GraphEdge } from '../models';
// @ts-ignore
import type { GraphNode } from '../models';
// @ts-ignore
import type { JobAid } from '../models';
// @ts-ignore
import type { LayoutBodyDTO } from '../models';
// @ts-ignore
import type { Step } from '../models';
// @ts-ignore
import type { StepDocument } from '../models';
// @ts-ignore
import type { StepDocumentEssential } from '../models';
// @ts-ignore
import type { StepDocumentIdEssential } from '../models';
// @ts-ignore
import type { StepIdEssential } from '../models';
// @ts-ignore
import type { StepLink } from '../models';
// @ts-ignore
import type { StepLinkEssential } from '../models';
// @ts-ignore
import type { StepLinkIdEssential } from '../models';
// @ts-ignore
import type { StepRevision } from '../models';
// @ts-ignore
import type { StepRevisionEssential } from '../models';
// @ts-ignore
import type { StepRevisionIdEssential } from '../models';
// @ts-ignore
import type { User } from '../models';
// @ts-ignore
import type { UserIdEssential } from '../models';
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdditionalOptionsEssential} additionalOptionsEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        additionalOptionsControllerCreateEntity: async (additionalOptionsEssential: AdditionalOptionsEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'additionalOptionsEssential' is not null or undefined
            assertParamExists('additionalOptionsControllerCreateEntity', 'additionalOptionsEssential', additionalOptionsEssential)
            const localVarPath = `/additional/options/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(additionalOptionsEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        additionalOptionsControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('additionalOptionsControllerDeleteEntity', 'id', id)
            const localVarPath = `/additional/options/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        additionalOptionsControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/additional/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        additionalOptionsControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('additionalOptionsControllerGetById', 'id', id)
            const localVarPath = `/additional/options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdditionalOptionsIdEssential} additionalOptionsIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        additionalOptionsControllerUpdateEntity: async (additionalOptionsIdEssential: AdditionalOptionsIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'additionalOptionsIdEssential' is not null or undefined
            assertParamExists('additionalOptionsControllerUpdateEntity', 'additionalOptionsIdEssential', additionalOptionsIdEssential)
            const localVarPath = `/additional/options/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(additionalOptionsIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationControllerGetManagers: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('authenticationControllerGetManagers', 'email', email)
            const localVarPath = `/authentication/managers/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationControllerGetPossibleDelegates: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('authenticationControllerGetPossibleDelegates', 'email', email)
            const localVarPath = `/authentication/possible-delegates/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {boolean} [ascending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationControllerGetResponsibleUsers: async (email: string, ascending?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('authenticationControllerGetResponsibleUsers', 'email', email)
            const localVarPath = `/authentication/responsible-users/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ascending !== undefined) {
                localVarQueryParameter['ascending'] = ascending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} stepLinkId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationControllerIsOwnerOfStepLink: async (stepLinkId: string, email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepLinkId' is not null or undefined
            assertParamExists('authenticationControllerIsOwnerOfStepLink', 'stepLinkId', stepLinkId)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('authenticationControllerIsOwnerOfStepLink', 'email', email)
            const localVarPath = `/authentication/is-owner-of-step-link/{stepLinkId}/{email}`
                .replace(`{${"stepLinkId"}}`, encodeURIComponent(String(stepLinkId)))
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlAttestationEssential} controlAttestationEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationControllerCreateEntity: async (controlAttestationEssential: ControlAttestationEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlAttestationEssential' is not null or undefined
            assertParamExists('controlAttestationControllerCreateEntity', 'controlAttestationEssential', controlAttestationEssential)
            const localVarPath = `/control-attestation/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlAttestationEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlAttestationControllerDeleteEntity', 'id', id)
            const localVarPath = `/control-attestation/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationControllerFindEntity: async (findByColumnQueryUUID: FindByColumnQueryUUID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findByColumnQueryUUID' is not null or undefined
            assertParamExists('controlAttestationControllerFindEntity', 'findByColumnQueryUUID', findByColumnQueryUUID)
            const localVarPath = `/control-attestation/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findByColumnQueryUUID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control-attestation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationControllerGetAllEntities: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('controlAttestationControllerGetAllEntities', 'instant', instant)
            const localVarPath = `/control-attestation/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlAttestationControllerGetById', 'id', id)
            const localVarPath = `/control-attestation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationControllerRemoveEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlAttestationControllerRemoveEntity', 'id', id)
            const localVarPath = `/control-attestation/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlAttestationIdEssential} controlAttestationIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationControllerUpdateEntity: async (controlAttestationIdEssential: ControlAttestationIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlAttestationIdEssential' is not null or undefined
            assertParamExists('controlAttestationControllerUpdateEntity', 'controlAttestationIdEssential', controlAttestationIdEssential)
            const localVarPath = `/control-attestation/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlAttestationIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlAttestationDocumentEssential} controlAttestationDocumentEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationDocumentControllerCreateEntity: async (controlAttestationDocumentEssential: ControlAttestationDocumentEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlAttestationDocumentEssential' is not null or undefined
            assertParamExists('controlAttestationDocumentControllerCreateEntity', 'controlAttestationDocumentEssential', controlAttestationDocumentEssential)
            const localVarPath = `/control-attestation-document/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlAttestationDocumentEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationDocumentControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlAttestationDocumentControllerDeleteEntity', 'id', id)
            const localVarPath = `/control-attestation-document/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationDocumentControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control-attestation-document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationDocumentControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlAttestationDocumentControllerGetById', 'id', id)
            const localVarPath = `/control-attestation-document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlAttestationDocumentIdEssential} controlAttestationDocumentIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationDocumentControllerUpdateEntity: async (controlAttestationDocumentIdEssential: ControlAttestationDocumentIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlAttestationDocumentIdEssential' is not null or undefined
            assertParamExists('controlAttestationDocumentControllerUpdateEntity', 'controlAttestationDocumentIdEssential', controlAttestationDocumentIdEssential)
            const localVarPath = `/control-attestation-document/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlAttestationDocumentIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlAttestationStepRevisionEssential} controlAttestationStepRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationStepRevisionControllerCreateEntity: async (controlAttestationStepRevisionEssential: ControlAttestationStepRevisionEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlAttestationStepRevisionEssential' is not null or undefined
            assertParamExists('controlAttestationStepRevisionControllerCreateEntity', 'controlAttestationStepRevisionEssential', controlAttestationStepRevisionEssential)
            const localVarPath = `/control-attestation-step-revision/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlAttestationStepRevisionEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationStepRevisionControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlAttestationStepRevisionControllerDeleteEntity', 'id', id)
            const localVarPath = `/control-attestation-step-revision/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationStepRevisionControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control-attestation-step-revision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationStepRevisionControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlAttestationStepRevisionControllerGetById', 'id', id)
            const localVarPath = `/control-attestation-step-revision/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlAttestationStepRevisionIdEssential} controlAttestationStepRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationStepRevisionControllerUpdateEntity: async (controlAttestationStepRevisionIdEssential: ControlAttestationStepRevisionIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlAttestationStepRevisionIdEssential' is not null or undefined
            assertParamExists('controlAttestationStepRevisionControllerUpdateEntity', 'controlAttestationStepRevisionIdEssential', controlAttestationStepRevisionIdEssential)
            const localVarPath = `/control-attestation-step-revision/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlAttestationStepRevisionIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerCreateEntity: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('controlControllerCreateEntity', 'body', body)
            const localVarPath = `/control/control/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlControllerDeleteEntity', 'id', id)
            const localVarPath = `/control/control/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control/control`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlControllerGetById', 'id', id)
            const localVarPath = `/control/control/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlIdEssential} controlIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerUpdateEntity: async (controlIdEssential: ControlIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlIdEssential' is not null or undefined
            assertParamExists('controlControllerUpdateEntity', 'controlIdEssential', controlIdEssential)
            const localVarPath = `/control/control/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlRevisionEssential} controlRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerCreateEntity: async (controlRevisionEssential: ControlRevisionEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlRevisionEssential' is not null or undefined
            assertParamExists('controlRevisionControllerCreateEntity', 'controlRevisionEssential', controlRevisionEssential)
            const localVarPath = `/control/control-revision/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlRevisionEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlRevisionControllerDeleteEntity', 'id', id)
            const localVarPath = `/control/control-revision/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerFindEntity: async (findByColumnQueryUUID: FindByColumnQueryUUID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findByColumnQueryUUID' is not null or undefined
            assertParamExists('controlRevisionControllerFindEntity', 'findByColumnQueryUUID', findByColumnQueryUUID)
            const localVarPath = `/control/control-revision/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findByColumnQueryUUID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control/control-revision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerGetAllEntities: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('controlRevisionControllerGetAllEntities', 'instant', instant)
            const localVarPath = `/control/control-revision/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlRevisionControllerGetById', 'id', id)
            const localVarPath = `/control/control-revision/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerRemoveEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlRevisionControllerRemoveEntity', 'id', id)
            const localVarPath = `/control/control-revision/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlRevisionIdEssential} controlRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerUpdateEntity: async (controlRevisionIdEssential: ControlRevisionIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlRevisionIdEssential' is not null or undefined
            assertParamExists('controlRevisionControllerUpdateEntity', 'controlRevisionIdEssential', controlRevisionIdEssential)
            const localVarPath = `/control/control-revision/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlRevisionIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlStepEssential} controlStepEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerCreateEntity: async (controlStepEssential: ControlStepEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlStepEssential' is not null or undefined
            assertParamExists('controlStepControllerCreateEntity', 'controlStepEssential', controlStepEssential)
            const localVarPath = `/control/control-step/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlStepEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlStepControllerDeleteEntity', 'id', id)
            const localVarPath = `/control/control-step/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerFindEntity: async (findByColumnQueryUUID: FindByColumnQueryUUID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findByColumnQueryUUID' is not null or undefined
            assertParamExists('controlStepControllerFindEntity', 'findByColumnQueryUUID', findByColumnQueryUUID)
            const localVarPath = `/control/control-step/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findByColumnQueryUUID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control/control-step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetAllEntities: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('controlStepControllerGetAllEntities', 'instant', instant)
            const localVarPath = `/control/control-step/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlStepControllerGetById', 'id', id)
            const localVarPath = `/control/control-step/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} control 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetControlStepsByControl: async (control: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'control' is not null or undefined
            assertParamExists('controlStepControllerGetControlStepsByControl', 'control', control)
            const localVarPath = `/control/control-step/by-control/{control}`
                .replace(`{${"control"}}`, encodeURIComponent(String(control)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} step 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetControlStepsByStep: async (step: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'step' is not null or undefined
            assertParamExists('controlStepControllerGetControlStepsByStep', 'step', step)
            const localVarPath = `/control/control-step/by-step/{step}`
                .replace(`{${"step"}}`, encodeURIComponent(String(step)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerRemoveEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlStepControllerRemoveEntity', 'id', id)
            const localVarPath = `/control/control-step/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlStepIdEssential} controlStepIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerUpdateEntity: async (controlStepIdEssential: ControlStepIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlStepIdEssential' is not null or undefined
            assertParamExists('controlStepControllerUpdateEntity', 'controlStepIdEssential', controlStepIdEssential)
            const localVarPath = `/control/control-step/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlStepIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreTasksApiControllerImportAndSaveControls: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/import-controls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} zone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreTasksApiControllerImportAndSaveSteps: async (zone: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zone' is not null or undefined
            assertParamExists('coreTasksApiControllerImportAndSaveSteps', 'zone', zone)
            const localVarPath = `/import-steps/{zone}`
                .replace(`{${"zone"}}`, encodeURIComponent(String(zone)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} zone 
         * @param {string} spreadsheetName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreTasksApiControllerImportAndSaveStepsWithSpreadsheet: async (zone: string, spreadsheetName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zone' is not null or undefined
            assertParamExists('coreTasksApiControllerImportAndSaveStepsWithSpreadsheet', 'zone', zone)
            // verify required parameter 'spreadsheetName' is not null or undefined
            assertParamExists('coreTasksApiControllerImportAndSaveStepsWithSpreadsheet', 'spreadsheetName', spreadsheetName)
            const localVarPath = `/import-steps/{zone}/{spreadsheetName}`
                .replace(`{${"zone"}}`, encodeURIComponent(String(zone)))
                .replace(`{${"spreadsheetName"}}`, encodeURIComponent(String(spreadsheetName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreTasksApiControllerPing: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} to 
         * @param {string} subject 
         * @param {EmailParams} emailParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailControllerSendEmail: async (to: Array<string>, subject: string, emailParams: EmailParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'to' is not null or undefined
            assertParamExists('emailControllerSendEmail', 'to', to)
            // verify required parameter 'subject' is not null or undefined
            assertParamExists('emailControllerSendEmail', 'subject', subject)
            // verify required parameter 'emailParams' is not null or undefined
            assertParamExists('emailControllerSendEmail', 'emailParams', emailParams)
            const localVarPath = `/notification/email/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (to) {
                localVarQueryParameter['to'] = to;
            }

            if (subject !== undefined) {
                localVarQueryParameter['subject'] = subject;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDataControllerGetAllEmployeeData: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/workday/employee/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDataControllerGetEmployee: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('employeeDataControllerGetEmployee', 'email', email)
            const localVarPath = `/workday/employee/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDataControllerGetEmployeeLayout: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/workday/employee/layout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {string} level 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphControllerGetLayoutData: async (instant: string, level: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('graphControllerGetLayoutData', 'instant', instant)
            // verify required parameter 'level' is not null or undefined
            assertParamExists('graphControllerGetLayoutData', 'level', level)
            const localVarPath = `/graph/data/atlas-utility-api/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {LayoutBodyDTO} layoutBodyDTO 
         * @param {string} [algoType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphControllerGetNestedLayout: async (instant: string, layoutBodyDTO: LayoutBodyDTO, algoType?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('graphControllerGetNestedLayout', 'instant', instant)
            // verify required parameter 'layoutBodyDTO' is not null or undefined
            assertParamExists('graphControllerGetNestedLayout', 'layoutBodyDTO', layoutBodyDTO)
            const localVarPath = `/graph/layout/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (algoType !== undefined) {
                localVarQueryParameter['algoType'] = algoType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(layoutBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {LayoutBodyDTO} layoutBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphControllerGetRenderedEdges: async (instant: string, layoutBodyDTO: LayoutBodyDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('graphControllerGetRenderedEdges', 'instant', instant)
            // verify required parameter 'layoutBodyDTO' is not null or undefined
            assertParamExists('graphControllerGetRenderedEdges', 'layoutBodyDTO', layoutBodyDTO)
            const localVarPath = `/graph/edges/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(layoutBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobAidControllerGetFromUrl: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/workday/jobaid/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerCreateEntity: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('stepControllerCreateEntity', 'body', body)
            const localVarPath = `/process/step/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/step/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepControllerGetById', 'id', id)
            const localVarPath = `/process/step/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepIdEssential} stepIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerUpdateEntity: async (stepIdEssential: StepIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepIdEssential' is not null or undefined
            assertParamExists('stepControllerUpdateEntity', 'stepIdEssential', stepIdEssential)
            const localVarPath = `/process/step/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepDocumentEssential} stepDocumentEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepDocumentControllerCreateEntity: async (stepDocumentEssential: StepDocumentEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepDocumentEssential' is not null or undefined
            assertParamExists('stepDocumentControllerCreateEntity', 'stepDocumentEssential', stepDocumentEssential)
            const localVarPath = `/step-document/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepDocumentEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepDocumentControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepDocumentControllerDeleteEntity', 'id', id)
            const localVarPath = `/step-document/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepDocumentControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/step-document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepDocumentControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepDocumentControllerGetById', 'id', id)
            const localVarPath = `/step-document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepDocumentIdEssential} stepDocumentIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepDocumentControllerUpdateEntity: async (stepDocumentIdEssential: StepDocumentIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepDocumentIdEssential' is not null or undefined
            assertParamExists('stepDocumentControllerUpdateEntity', 'stepDocumentIdEssential', stepDocumentIdEssential)
            const localVarPath = `/step-document/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepDocumentIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerCountTargets: async (instant: string, requestBody: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('stepLinkControllerCountTargets', 'instant', instant)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('stepLinkControllerCountTargets', 'requestBody', requestBody)
            const localVarPath = `/process/step-link/count/targets/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepLinkEssential} stepLinkEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerCreateEntity: async (stepLinkEssential: StepLinkEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepLinkEssential' is not null or undefined
            assertParamExists('stepLinkControllerCreateEntity', 'stepLinkEssential', stepLinkEssential)
            const localVarPath = `/process/step-link/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepLinkEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepLinkControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/step-link/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerFindAllBySource: async (instant: string, body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('stepLinkControllerFindAllBySource', 'instant', instant)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('stepLinkControllerFindAllBySource', 'body', body)
            const localVarPath = `/process/step-link/source/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerFindAllByTarget: async (instant: string, body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('stepLinkControllerFindAllByTarget', 'instant', instant)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('stepLinkControllerFindAllByTarget', 'body', body)
            const localVarPath = `/process/step-link/target/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerFindEntity: async (findByColumnQueryUUID: FindByColumnQueryUUID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findByColumnQueryUUID' is not null or undefined
            assertParamExists('stepLinkControllerFindEntity', 'findByColumnQueryUUID', findByColumnQueryUUID)
            const localVarPath = `/process/step-link/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findByColumnQueryUUID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/step-link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerGetAllEntities: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('stepLinkControllerGetAllEntities', 'instant', instant)
            const localVarPath = `/process/step-link/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepLinkControllerGetById', 'id', id)
            const localVarPath = `/process/step-link/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerRemoveEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepLinkControllerRemoveEntity', 'id', id)
            const localVarPath = `/process/step-link/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} source 
         * @param {string} target 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerRemoveStepLinksBySourceAndTarget: async (source: string, target: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('stepLinkControllerRemoveStepLinksBySourceAndTarget', 'source', source)
            // verify required parameter 'target' is not null or undefined
            assertParamExists('stepLinkControllerRemoveStepLinksBySourceAndTarget', 'target', target)
            const localVarPath = `/process/step-link/delete/{source}/{target}`
                .replace(`{${"source"}}`, encodeURIComponent(String(source)))
                .replace(`{${"target"}}`, encodeURIComponent(String(target)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepLinkIdEssential} stepLinkIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerUpdateEntity: async (stepLinkIdEssential: StepLinkIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepLinkIdEssential' is not null or undefined
            assertParamExists('stepLinkControllerUpdateEntity', 'stepLinkIdEssential', stepLinkIdEssential)
            const localVarPath = `/process/step-link/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepLinkIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<StepRevision>} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerCreateBulkEntityForce: async (stepRevision: Array<StepRevision>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepRevision' is not null or undefined
            assertParamExists('stepRevisionControllerCreateBulkEntityForce', 'stepRevision', stepRevision)
            const localVarPath = `/process/step-revision/create/bulk/force`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepRevision, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepRevisionEssential} stepRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerCreateEntity: async (stepRevisionEssential: StepRevisionEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepRevisionEssential' is not null or undefined
            assertParamExists('stepRevisionControllerCreateEntity', 'stepRevisionEssential', stepRevisionEssential)
            const localVarPath = `/process/step-revision/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepRevisionEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepRevision} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerCreateEntityForce: async (stepRevision: StepRevision, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepRevision' is not null or undefined
            assertParamExists('stepRevisionControllerCreateEntityForce', 'stepRevision', stepRevision)
            const localVarPath = `/process/step-revision/create/force`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepRevision, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepRevisionControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/step-revision/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fieldName 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindAllDistinct: async (fieldName: string, instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldName' is not null or undefined
            assertParamExists('stepRevisionControllerFindAllDistinct', 'fieldName', fieldName)
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('stepRevisionControllerFindAllDistinct', 'instant', instant)
            const localVarPath = `/process/step-revision/distinct/{fieldName}`
                .replace(`{${"fieldName"}}`, encodeURIComponent(String(fieldName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (instant !== undefined) {
                localVarQueryParameter['instant'] = (instant as any instanceof Date) ?
                    (instant as any).toISOString() :
                    instant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fieldName 
         * @param {FilterDTO} filterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindAllDistinctGivenFilter: async (fieldName: string, filterDTO: FilterDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldName' is not null or undefined
            assertParamExists('stepRevisionControllerFindAllDistinctGivenFilter', 'fieldName', fieldName)
            // verify required parameter 'filterDTO' is not null or undefined
            assertParamExists('stepRevisionControllerFindAllDistinctGivenFilter', 'filterDTO', filterDTO)
            const localVarPath = `/process/step-revision/distinct-filter/{fieldName}`
                .replace(`{${"fieldName"}}`, encodeURIComponent(String(fieldName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {FilterDTO} filterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindAllGivenFilter: async (instant: string, filterDTO: FilterDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('stepRevisionControllerFindAllGivenFilter', 'instant', instant)
            // verify required parameter 'filterDTO' is not null or undefined
            assertParamExists('stepRevisionControllerFindAllGivenFilter', 'filterDTO', filterDTO)
            const localVarPath = `/process/step-revision/filter/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindAllSources: async (instant: string, body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('stepRevisionControllerFindAllSources', 'instant', instant)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('stepRevisionControllerFindAllSources', 'body', body)
            const localVarPath = `/process/step-revision/links/sources/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindAllTargets: async (instant: string, body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('stepRevisionControllerFindAllTargets', 'instant', instant)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('stepRevisionControllerFindAllTargets', 'body', body)
            const localVarPath = `/process/step-revision/links/targets/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindByOwner: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('stepRevisionControllerFindByOwner', 'email', email)
            const localVarPath = `/process/step-revision/owner/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindByResponsibleUser: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('stepRevisionControllerFindByResponsibleUser', 'email', email)
            const localVarPath = `/process/step-revision/revisions-by-responsible-user/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindByStepId: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepRevisionControllerFindByStepId', 'id', id)
            const localVarPath = `/process/step-revision/step/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} stepName 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindByStepName: async (stepName: string, instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepName' is not null or undefined
            assertParamExists('stepRevisionControllerFindByStepName', 'stepName', stepName)
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('stepRevisionControllerFindByStepName', 'instant', instant)
            const localVarPath = `/process/step-revision/distinct-find-by-step-name/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (stepName !== undefined) {
                localVarQueryParameter['stepName'] = stepName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindEntity: async (findByColumnQueryUUID: FindByColumnQueryUUID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findByColumnQueryUUID' is not null or undefined
            assertParamExists('stepRevisionControllerFindEntity', 'findByColumnQueryUUID', findByColumnQueryUUID)
            const localVarPath = `/process/step-revision/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findByColumnQueryUUID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<StepRevision>} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindNewValues: async (stepRevision: Array<StepRevision>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepRevision' is not null or undefined
            assertParamExists('stepRevisionControllerFindNewValues', 'stepRevision', stepRevision)
            const localVarPath = `/process/step-revision/check-new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepRevision, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/step-revision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerGetAllEntities: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('stepRevisionControllerGetAllEntities', 'instant', instant)
            const localVarPath = `/process/step-revision/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepRevisionControllerGetById', 'id', id)
            const localVarPath = `/process/step-revision/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerGetUpdateAuthor: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepRevisionControllerGetUpdateAuthor', 'id', id)
            const localVarPath = `/process/step-revision/audit/updated/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerRemoveEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepRevisionControllerRemoveEntity', 'id', id)
            const localVarPath = `/process/step-revision/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepRevisionIdEssential} stepRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerUpdateEntity: async (stepRevisionIdEssential: StepRevisionIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepRevisionIdEssential' is not null or undefined
            assertParamExists('stepRevisionControllerUpdateEntity', 'stepRevisionIdEssential', stepRevisionIdEssential)
            const localVarPath = `/process/step-revision/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepRevisionIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepRevision} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerUpdateEntityForce: async (stepRevision: StepRevision, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepRevision' is not null or undefined
            assertParamExists('stepRevisionControllerUpdateEntityForce', 'stepRevision', stepRevision)
            const localVarPath = `/process/step-revision/update/force`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepRevision, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserIdEssential} userIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateEntity: async (userIdEssential: UserIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userIdEssential' is not null or undefined
            assertParamExists('userControllerCreateEntity', 'userIdEssential', userIdEssential)
            const localVarPath = `/preference/users/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerDeleteEntity', 'id', id)
            const localVarPath = `/preference/users/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/preference/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerGetById', 'id', id)
            const localVarPath = `/preference/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUserRole: async (user: User, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('userControllerUpdateUserRole', 'user', user)
            const localVarPath = `/preference/users/role/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdditionalOptionsEssential} additionalOptionsEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async additionalOptionsControllerCreateEntity(additionalOptionsEssential: AdditionalOptionsEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionalOptions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.additionalOptionsControllerCreateEntity(additionalOptionsEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.additionalOptionsControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async additionalOptionsControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.additionalOptionsControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.additionalOptionsControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async additionalOptionsControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdditionalOptions>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.additionalOptionsControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.additionalOptionsControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async additionalOptionsControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionalOptions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.additionalOptionsControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.additionalOptionsControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdditionalOptionsIdEssential} additionalOptionsIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async additionalOptionsControllerUpdateEntity(additionalOptionsIdEssential: AdditionalOptionsIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionalOptions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.additionalOptionsControllerUpdateEntity(additionalOptionsIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.additionalOptionsControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationControllerGetManagers(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationControllerGetManagers(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.authenticationControllerGetManagers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationControllerGetPossibleDelegates(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationControllerGetPossibleDelegates(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.authenticationControllerGetPossibleDelegates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} email 
         * @param {boolean} [ascending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationControllerGetResponsibleUsers(email: string, ascending?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationControllerGetResponsibleUsers(email, ascending, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.authenticationControllerGetResponsibleUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} stepLinkId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationControllerIsOwnerOfStepLink(stepLinkId: string, email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationControllerIsOwnerOfStepLink(stepLinkId, email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.authenticationControllerIsOwnerOfStepLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlAttestationEssential} controlAttestationEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlAttestationControllerCreateEntity(controlAttestationEssential: ControlAttestationEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlAttestation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlAttestationControllerCreateEntity(controlAttestationEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlAttestationControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlAttestationControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlAttestationControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlAttestationControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlAttestationControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlAttestation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlAttestationControllerFindEntity(findByColumnQueryUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlAttestationControllerFindEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlAttestationControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlAttestation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlAttestationControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlAttestationControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlAttestationControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlAttestation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlAttestationControllerGetAllEntities(instant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlAttestationControllerGetAllEntities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlAttestationControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlAttestation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlAttestationControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlAttestationControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlAttestationControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlAttestation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlAttestationControllerRemoveEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlAttestationControllerRemoveEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlAttestationIdEssential} controlAttestationIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlAttestationControllerUpdateEntity(controlAttestationIdEssential: ControlAttestationIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlAttestation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlAttestationControllerUpdateEntity(controlAttestationIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlAttestationControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlAttestationDocumentEssential} controlAttestationDocumentEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlAttestationDocumentControllerCreateEntity(controlAttestationDocumentEssential: ControlAttestationDocumentEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlAttestationDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlAttestationDocumentControllerCreateEntity(controlAttestationDocumentEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlAttestationDocumentControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlAttestationDocumentControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlAttestationDocumentControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlAttestationDocumentControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlAttestationDocumentControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlAttestationDocument>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlAttestationDocumentControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlAttestationDocumentControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlAttestationDocumentControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlAttestationDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlAttestationDocumentControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlAttestationDocumentControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlAttestationDocumentIdEssential} controlAttestationDocumentIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlAttestationDocumentControllerUpdateEntity(controlAttestationDocumentIdEssential: ControlAttestationDocumentIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlAttestationDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlAttestationDocumentControllerUpdateEntity(controlAttestationDocumentIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlAttestationDocumentControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlAttestationStepRevisionEssential} controlAttestationStepRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlAttestationStepRevisionControllerCreateEntity(controlAttestationStepRevisionEssential: ControlAttestationStepRevisionEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlAttestationStepRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlAttestationStepRevisionControllerCreateEntity(controlAttestationStepRevisionEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlAttestationStepRevisionControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlAttestationStepRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlAttestationStepRevisionControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlAttestationStepRevisionControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlAttestationStepRevisionControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlAttestationStepRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlAttestationStepRevisionControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlAttestationStepRevisionControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlAttestationStepRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlAttestationStepRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlAttestationStepRevisionControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlAttestationStepRevisionControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlAttestationStepRevisionIdEssential} controlAttestationStepRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlAttestationStepRevisionControllerUpdateEntity(controlAttestationStepRevisionIdEssential: ControlAttestationStepRevisionIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlAttestationStepRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlAttestationStepRevisionControllerUpdateEntity(controlAttestationStepRevisionIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlAttestationStepRevisionControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlControllerCreateEntity(body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Control>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlControllerCreateEntity(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Control>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Control>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlIdEssential} controlIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlControllerUpdateEntity(controlIdEssential: ControlIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Control>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlControllerUpdateEntity(controlIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlRevisionEssential} controlRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerCreateEntity(controlRevisionEssential: ControlRevisionEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerCreateEntity(controlRevisionEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlRevisionControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlRevisionControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerFindEntity(findByColumnQueryUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlRevisionControllerFindEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlRevisionControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerGetAllEntities(instant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlRevisionControllerGetAllEntities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlRevisionControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerRemoveEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlRevisionControllerRemoveEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlRevisionIdEssential} controlRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerUpdateEntity(controlRevisionIdEssential: ControlRevisionIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerUpdateEntity(controlRevisionIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlRevisionControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlStepEssential} controlStepEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerCreateEntity(controlStepEssential: ControlStepEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerCreateEntity(controlStepEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlStepControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlStepControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlStep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerFindEntity(findByColumnQueryUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlStepControllerFindEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlStep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlStepControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlStep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerGetAllEntities(instant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlStepControllerGetAllEntities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlStepControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} control 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerGetControlStepsByControl(control: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlStep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerGetControlStepsByControl(control, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlStepControllerGetControlStepsByControl']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} step 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerGetControlStepsByStep(step: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlStep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerGetControlStepsByStep(step, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlStepControllerGetControlStepsByStep']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerRemoveEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlStepControllerRemoveEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlStepIdEssential} controlStepIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerUpdateEntity(controlStepIdEssential: ControlStepIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerUpdateEntity(controlStepIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlStepControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreTasksApiControllerImportAndSaveControls(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreTasksApiControllerImportAndSaveControls(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.coreTasksApiControllerImportAndSaveControls']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} zone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreTasksApiControllerImportAndSaveSteps(zone: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Step>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreTasksApiControllerImportAndSaveSteps(zone, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.coreTasksApiControllerImportAndSaveSteps']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} zone 
         * @param {string} spreadsheetName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreTasksApiControllerImportAndSaveStepsWithSpreadsheet(zone: string, spreadsheetName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Step>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreTasksApiControllerImportAndSaveStepsWithSpreadsheet(zone, spreadsheetName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.coreTasksApiControllerImportAndSaveStepsWithSpreadsheet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreTasksApiControllerPing(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreTasksApiControllerPing(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.coreTasksApiControllerPing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} to 
         * @param {string} subject 
         * @param {EmailParams} emailParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailControllerSendEmail(to: Array<string>, subject: string, emailParams: EmailParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailControllerSendEmail(to, subject, emailParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.emailControllerSendEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDataControllerGetAllEmployeeData(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDataControllerGetAllEmployeeData(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.employeeDataControllerGetAllEmployeeData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDataControllerGetEmployee(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDataControllerGetEmployee(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.employeeDataControllerGetEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDataControllerGetEmployeeLayout(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeLayoutResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDataControllerGetEmployeeLayout(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.employeeDataControllerGetEmployeeLayout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {string} level 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async graphControllerGetLayoutData(instant: string, level: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.graphControllerGetLayoutData(instant, level, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.graphControllerGetLayoutData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {LayoutBodyDTO} layoutBodyDTO 
         * @param {string} [algoType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async graphControllerGetNestedLayout(instant: string, layoutBodyDTO: LayoutBodyDTO, algoType?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GraphNode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.graphControllerGetNestedLayout(instant, layoutBodyDTO, algoType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.graphControllerGetNestedLayout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {LayoutBodyDTO} layoutBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async graphControllerGetRenderedEdges(instant: string, layoutBodyDTO: LayoutBodyDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GraphEdge>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.graphControllerGetRenderedEdges(instant, layoutBodyDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.graphControllerGetRenderedEdges']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobAidControllerGetFromUrl(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobAid>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jobAidControllerGetFromUrl(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.jobAidControllerGetFromUrl']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerCreateEntity(body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Step>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerCreateEntity(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Step>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Step>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StepIdEssential} stepIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerUpdateEntity(stepIdEssential: StepIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Step>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerUpdateEntity(stepIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StepDocumentEssential} stepDocumentEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepDocumentControllerCreateEntity(stepDocumentEssential: StepDocumentEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepDocumentControllerCreateEntity(stepDocumentEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepDocumentControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepDocumentControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepDocumentControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepDocumentControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepDocumentControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepDocument>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepDocumentControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepDocumentControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepDocumentControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepDocumentControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepDocumentControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StepDocumentIdEssential} stepDocumentIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepDocumentControllerUpdateEntity(stepDocumentIdEssential: StepDocumentIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepDocumentControllerUpdateEntity(stepDocumentIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepDocumentControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerCountTargets(instant: string, requestBody: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerCountTargets(instant, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepLinkControllerCountTargets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StepLinkEssential} stepLinkEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerCreateEntity(stepLinkEssential: StepLinkEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerCreateEntity(stepLinkEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepLinkControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepLinkControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerFindAllBySource(instant: string, body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepLink>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerFindAllBySource(instant, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepLinkControllerFindAllBySource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerFindAllByTarget(instant: string, body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepLink>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerFindAllByTarget(instant, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepLinkControllerFindAllByTarget']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepLink>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerFindEntity(findByColumnQueryUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepLinkControllerFindEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepLink>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepLinkControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepLink>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerGetAllEntities(instant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepLinkControllerGetAllEntities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepLinkControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerRemoveEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepLinkControllerRemoveEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} source 
         * @param {string} target 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerRemoveStepLinksBySourceAndTarget(source: string, target: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerRemoveStepLinksBySourceAndTarget(source, target, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepLinkControllerRemoveStepLinksBySourceAndTarget']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StepLinkIdEssential} stepLinkIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerUpdateEntity(stepLinkIdEssential: StepLinkIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerUpdateEntity(stepLinkIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepLinkControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<StepRevision>} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerCreateBulkEntityForce(stepRevision: Array<StepRevision>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerCreateBulkEntityForce(stepRevision, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerCreateBulkEntityForce']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StepRevisionEssential} stepRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerCreateEntity(stepRevisionEssential: StepRevisionEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerCreateEntity(stepRevisionEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StepRevision} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerCreateEntityForce(stepRevision: StepRevision, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerCreateEntityForce(stepRevision, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerCreateEntityForce']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} fieldName 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerFindAllDistinct(fieldName: string, instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerFindAllDistinct(fieldName, instant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerFindAllDistinct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} fieldName 
         * @param {FilterDTO} filterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerFindAllDistinctGivenFilter(fieldName: string, filterDTO: FilterDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerFindAllDistinctGivenFilter(fieldName, filterDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerFindAllDistinctGivenFilter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {FilterDTO} filterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerFindAllGivenFilter(instant: string, filterDTO: FilterDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerFindAllGivenFilter(instant, filterDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerFindAllGivenFilter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerFindAllSources(instant: string, body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerFindAllSources(instant, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerFindAllSources']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerFindAllTargets(instant: string, body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerFindAllTargets(instant, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerFindAllTargets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerFindByOwner(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: Array<StepRevision>; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerFindByOwner(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerFindByOwner']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerFindByResponsibleUser(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerFindByResponsibleUser(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerFindByResponsibleUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerFindByStepId(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerFindByStepId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerFindByStepId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} stepName 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerFindByStepName(stepName: string, instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerFindByStepName(stepName, instant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerFindByStepName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerFindEntity(findByColumnQueryUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerFindEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<StepRevision>} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerFindNewValues(stepRevision: Array<StepRevision>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerFindNewValues(stepRevision, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerFindNewValues']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerGetAllEntities(instant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerGetAllEntities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerGetUpdateAuthor(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Auth0UserDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerGetUpdateAuthor(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerGetUpdateAuthor']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerRemoveEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerRemoveEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StepRevisionIdEssential} stepRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerUpdateEntity(stepRevisionIdEssential: StepRevisionIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerUpdateEntity(stepRevisionIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StepRevision} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerUpdateEntityForce(stepRevision: StepRevision, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerUpdateEntityForce(stepRevision, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerUpdateEntityForce']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserIdEssential} userIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCreateEntity(userIdEssential: UserIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCreateEntity(userIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.userControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.userControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.userControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.userControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateUserRole(user: User, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateUserRole(user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.userControllerUpdateUserRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {AdditionalOptionsEssential} additionalOptionsEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        additionalOptionsControllerCreateEntity(additionalOptionsEssential: AdditionalOptionsEssential, options?: RawAxiosRequestConfig): AxiosPromise<AdditionalOptions> {
            return localVarFp.additionalOptionsControllerCreateEntity(additionalOptionsEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        additionalOptionsControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.additionalOptionsControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        additionalOptionsControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<AdditionalOptions>> {
            return localVarFp.additionalOptionsControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        additionalOptionsControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<AdditionalOptions> {
            return localVarFp.additionalOptionsControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdditionalOptionsIdEssential} additionalOptionsIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        additionalOptionsControllerUpdateEntity(additionalOptionsIdEssential: AdditionalOptionsIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<AdditionalOptions> {
            return localVarFp.additionalOptionsControllerUpdateEntity(additionalOptionsIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationControllerGetManagers(email: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.authenticationControllerGetManagers(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationControllerGetPossibleDelegates(email: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<EmployeeData>> {
            return localVarFp.authenticationControllerGetPossibleDelegates(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {boolean} [ascending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationControllerGetResponsibleUsers(email: string, ascending?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.authenticationControllerGetResponsibleUsers(email, ascending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} stepLinkId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationControllerIsOwnerOfStepLink(stepLinkId: string, email: string, options?: RawAxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.authenticationControllerIsOwnerOfStepLink(stepLinkId, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlAttestationEssential} controlAttestationEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationControllerCreateEntity(controlAttestationEssential: ControlAttestationEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlAttestation> {
            return localVarFp.controlAttestationControllerCreateEntity(controlAttestationEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.controlAttestationControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlAttestation>> {
            return localVarFp.controlAttestationControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlAttestation>> {
            return localVarFp.controlAttestationControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlAttestation>> {
            return localVarFp.controlAttestationControllerGetAllEntities(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ControlAttestation> {
            return localVarFp.controlAttestationControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ControlAttestation> {
            return localVarFp.controlAttestationControllerRemoveEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlAttestationIdEssential} controlAttestationIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationControllerUpdateEntity(controlAttestationIdEssential: ControlAttestationIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlAttestation> {
            return localVarFp.controlAttestationControllerUpdateEntity(controlAttestationIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlAttestationDocumentEssential} controlAttestationDocumentEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationDocumentControllerCreateEntity(controlAttestationDocumentEssential: ControlAttestationDocumentEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlAttestationDocument> {
            return localVarFp.controlAttestationDocumentControllerCreateEntity(controlAttestationDocumentEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationDocumentControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.controlAttestationDocumentControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationDocumentControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlAttestationDocument>> {
            return localVarFp.controlAttestationDocumentControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationDocumentControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ControlAttestationDocument> {
            return localVarFp.controlAttestationDocumentControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlAttestationDocumentIdEssential} controlAttestationDocumentIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationDocumentControllerUpdateEntity(controlAttestationDocumentIdEssential: ControlAttestationDocumentIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlAttestationDocument> {
            return localVarFp.controlAttestationDocumentControllerUpdateEntity(controlAttestationDocumentIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlAttestationStepRevisionEssential} controlAttestationStepRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationStepRevisionControllerCreateEntity(controlAttestationStepRevisionEssential: ControlAttestationStepRevisionEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlAttestationStepRevision> {
            return localVarFp.controlAttestationStepRevisionControllerCreateEntity(controlAttestationStepRevisionEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationStepRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.controlAttestationStepRevisionControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationStepRevisionControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlAttestationStepRevision>> {
            return localVarFp.controlAttestationStepRevisionControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationStepRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ControlAttestationStepRevision> {
            return localVarFp.controlAttestationStepRevisionControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlAttestationStepRevisionIdEssential} controlAttestationStepRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlAttestationStepRevisionControllerUpdateEntity(controlAttestationStepRevisionIdEssential: ControlAttestationStepRevisionIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlAttestationStepRevision> {
            return localVarFp.controlAttestationStepRevisionControllerUpdateEntity(controlAttestationStepRevisionIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerCreateEntity(body: object, options?: RawAxiosRequestConfig): AxiosPromise<Control> {
            return localVarFp.controlControllerCreateEntity(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.controlControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<Control>> {
            return localVarFp.controlControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Control> {
            return localVarFp.controlControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlIdEssential} controlIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerUpdateEntity(controlIdEssential: ControlIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<Control> {
            return localVarFp.controlControllerUpdateEntity(controlIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlRevisionEssential} controlRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerCreateEntity(controlRevisionEssential: ControlRevisionEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlRevision> {
            return localVarFp.controlRevisionControllerCreateEntity(controlRevisionEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.controlRevisionControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlRevision>> {
            return localVarFp.controlRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlRevision>> {
            return localVarFp.controlRevisionControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlRevision>> {
            return localVarFp.controlRevisionControllerGetAllEntities(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ControlRevision> {
            return localVarFp.controlRevisionControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ControlRevision> {
            return localVarFp.controlRevisionControllerRemoveEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlRevisionIdEssential} controlRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerUpdateEntity(controlRevisionIdEssential: ControlRevisionIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlRevision> {
            return localVarFp.controlRevisionControllerUpdateEntity(controlRevisionIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlStepEssential} controlStepEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerCreateEntity(controlStepEssential: ControlStepEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlStep> {
            return localVarFp.controlStepControllerCreateEntity(controlStepEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.controlStepControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlStep>> {
            return localVarFp.controlStepControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlStep>> {
            return localVarFp.controlStepControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlStep>> {
            return localVarFp.controlStepControllerGetAllEntities(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ControlStep> {
            return localVarFp.controlStepControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} control 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetControlStepsByControl(control: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlStep>> {
            return localVarFp.controlStepControllerGetControlStepsByControl(control, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} step 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetControlStepsByStep(step: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlStep>> {
            return localVarFp.controlStepControllerGetControlStepsByStep(step, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ControlStep> {
            return localVarFp.controlStepControllerRemoveEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlStepIdEssential} controlStepIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerUpdateEntity(controlStepIdEssential: ControlStepIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlStep> {
            return localVarFp.controlStepControllerUpdateEntity(controlStepIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreTasksApiControllerImportAndSaveControls(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.coreTasksApiControllerImportAndSaveControls(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} zone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreTasksApiControllerImportAndSaveSteps(zone: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Step>> {
            return localVarFp.coreTasksApiControllerImportAndSaveSteps(zone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} zone 
         * @param {string} spreadsheetName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreTasksApiControllerImportAndSaveStepsWithSpreadsheet(zone: string, spreadsheetName: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Step>> {
            return localVarFp.coreTasksApiControllerImportAndSaveStepsWithSpreadsheet(zone, spreadsheetName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreTasksApiControllerPing(options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.coreTasksApiControllerPing(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} to 
         * @param {string} subject 
         * @param {EmailParams} emailParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailControllerSendEmail(to: Array<string>, subject: string, emailParams: EmailParams, options?: RawAxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.emailControllerSendEmail(to, subject, emailParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDataControllerGetAllEmployeeData(options?: RawAxiosRequestConfig): AxiosPromise<Array<EmployeeData>> {
            return localVarFp.employeeDataControllerGetAllEmployeeData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDataControllerGetEmployee(email: string, options?: RawAxiosRequestConfig): AxiosPromise<EmployeeData> {
            return localVarFp.employeeDataControllerGetEmployee(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDataControllerGetEmployeeLayout(options?: RawAxiosRequestConfig): AxiosPromise<EmployeeLayoutResult> {
            return localVarFp.employeeDataControllerGetEmployeeLayout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {string} level 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphControllerGetLayoutData(instant: string, level: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.graphControllerGetLayoutData(instant, level, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {LayoutBodyDTO} layoutBodyDTO 
         * @param {string} [algoType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphControllerGetNestedLayout(instant: string, layoutBodyDTO: LayoutBodyDTO, algoType?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<GraphNode>> {
            return localVarFp.graphControllerGetNestedLayout(instant, layoutBodyDTO, algoType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {LayoutBodyDTO} layoutBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphControllerGetRenderedEdges(instant: string, layoutBodyDTO: LayoutBodyDTO, options?: RawAxiosRequestConfig): AxiosPromise<Array<GraphEdge>> {
            return localVarFp.graphControllerGetRenderedEdges(instant, layoutBodyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobAidControllerGetFromUrl(options?: RawAxiosRequestConfig): AxiosPromise<Array<JobAid>> {
            return localVarFp.jobAidControllerGetFromUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerCreateEntity(body: object, options?: RawAxiosRequestConfig): AxiosPromise<Step> {
            return localVarFp.stepControllerCreateEntity(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.stepControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<Step>> {
            return localVarFp.stepControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Step> {
            return localVarFp.stepControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepIdEssential} stepIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerUpdateEntity(stepIdEssential: StepIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<Step> {
            return localVarFp.stepControllerUpdateEntity(stepIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepDocumentEssential} stepDocumentEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepDocumentControllerCreateEntity(stepDocumentEssential: StepDocumentEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepDocument> {
            return localVarFp.stepDocumentControllerCreateEntity(stepDocumentEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepDocumentControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.stepDocumentControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepDocumentControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<StepDocument>> {
            return localVarFp.stepDocumentControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepDocumentControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StepDocument> {
            return localVarFp.stepDocumentControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepDocumentIdEssential} stepDocumentIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepDocumentControllerUpdateEntity(stepDocumentIdEssential: StepDocumentIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepDocument> {
            return localVarFp.stepDocumentControllerUpdateEntity(stepDocumentIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerCountTargets(instant: string, requestBody: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.stepLinkControllerCountTargets(instant, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepLinkEssential} stepLinkEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerCreateEntity(stepLinkEssential: StepLinkEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepLink> {
            return localVarFp.stepLinkControllerCreateEntity(stepLinkEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.stepLinkControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerFindAllBySource(instant: string, body: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepLink>> {
            return localVarFp.stepLinkControllerFindAllBySource(instant, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerFindAllByTarget(instant: string, body: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepLink>> {
            return localVarFp.stepLinkControllerFindAllByTarget(instant, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepLink>> {
            return localVarFp.stepLinkControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<StepLink>> {
            return localVarFp.stepLinkControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepLink>> {
            return localVarFp.stepLinkControllerGetAllEntities(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StepLink> {
            return localVarFp.stepLinkControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StepLink> {
            return localVarFp.stepLinkControllerRemoveEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} source 
         * @param {string} target 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerRemoveStepLinksBySourceAndTarget(source: string, target: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.stepLinkControllerRemoveStepLinksBySourceAndTarget(source, target, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepLinkIdEssential} stepLinkIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerUpdateEntity(stepLinkIdEssential: StepLinkIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepLink> {
            return localVarFp.stepLinkControllerUpdateEntity(stepLinkIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<StepRevision>} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerCreateBulkEntityForce(stepRevision: Array<StepRevision>, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepRevision>> {
            return localVarFp.stepRevisionControllerCreateBulkEntityForce(stepRevision, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepRevisionEssential} stepRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerCreateEntity(stepRevisionEssential: StepRevisionEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepRevision> {
            return localVarFp.stepRevisionControllerCreateEntity(stepRevisionEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepRevision} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerCreateEntityForce(stepRevision: StepRevision, options?: RawAxiosRequestConfig): AxiosPromise<StepRevision> {
            return localVarFp.stepRevisionControllerCreateEntityForce(stepRevision, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.stepRevisionControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fieldName 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindAllDistinct(fieldName: string, instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Set<string>> {
            return localVarFp.stepRevisionControllerFindAllDistinct(fieldName, instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fieldName 
         * @param {FilterDTO} filterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindAllDistinctGivenFilter(fieldName: string, filterDTO: FilterDTO, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.stepRevisionControllerFindAllDistinctGivenFilter(fieldName, filterDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {FilterDTO} filterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindAllGivenFilter(instant: string, filterDTO: FilterDTO, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepRevision>> {
            return localVarFp.stepRevisionControllerFindAllGivenFilter(instant, filterDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindAllSources(instant: string, body: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepRevision>> {
            return localVarFp.stepRevisionControllerFindAllSources(instant, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindAllTargets(instant: string, body: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepRevision>> {
            return localVarFp.stepRevisionControllerFindAllTargets(instant, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindByOwner(email: string, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: { [key: string]: Array<StepRevision>; }; }> {
            return localVarFp.stepRevisionControllerFindByOwner(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindByResponsibleUser(email: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepRevision>> {
            return localVarFp.stepRevisionControllerFindByResponsibleUser(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindByStepId(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StepRevision> {
            return localVarFp.stepRevisionControllerFindByStepId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} stepName 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindByStepName(stepName: string, instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepRevision>> {
            return localVarFp.stepRevisionControllerFindByStepName(stepName, instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepRevision>> {
            return localVarFp.stepRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<StepRevision>} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindNewValues(stepRevision: Array<StepRevision>, options?: RawAxiosRequestConfig): AxiosPromise<FilterDTO> {
            return localVarFp.stepRevisionControllerFindNewValues(stepRevision, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<StepRevision>> {
            return localVarFp.stepRevisionControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepRevision>> {
            return localVarFp.stepRevisionControllerGetAllEntities(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StepRevision> {
            return localVarFp.stepRevisionControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerGetUpdateAuthor(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Auth0UserDTO> {
            return localVarFp.stepRevisionControllerGetUpdateAuthor(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StepRevision> {
            return localVarFp.stepRevisionControllerRemoveEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepRevisionIdEssential} stepRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerUpdateEntity(stepRevisionIdEssential: StepRevisionIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepRevision> {
            return localVarFp.stepRevisionControllerUpdateEntity(stepRevisionIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepRevision} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerUpdateEntityForce(stepRevision: StepRevision, options?: RawAxiosRequestConfig): AxiosPromise<StepRevision> {
            return localVarFp.stepRevisionControllerUpdateEntityForce(stepRevision, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserIdEssential} userIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateEntity(userIdEssential: UserIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.userControllerCreateEntity(userIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.userControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<User>> {
            return localVarFp.userControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.userControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUserRole(user: User, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.userControllerUpdateUserRole(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {AdditionalOptionsEssential} additionalOptionsEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public additionalOptionsControllerCreateEntity(additionalOptionsEssential: AdditionalOptionsEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).additionalOptionsControllerCreateEntity(additionalOptionsEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public additionalOptionsControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).additionalOptionsControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public additionalOptionsControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).additionalOptionsControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public additionalOptionsControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).additionalOptionsControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdditionalOptionsIdEssential} additionalOptionsIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public additionalOptionsControllerUpdateEntity(additionalOptionsIdEssential: AdditionalOptionsIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).additionalOptionsControllerUpdateEntity(additionalOptionsIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authenticationControllerGetManagers(email: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authenticationControllerGetManagers(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authenticationControllerGetPossibleDelegates(email: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authenticationControllerGetPossibleDelegates(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {boolean} [ascending] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authenticationControllerGetResponsibleUsers(email: string, ascending?: boolean, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authenticationControllerGetResponsibleUsers(email, ascending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} stepLinkId 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authenticationControllerIsOwnerOfStepLink(stepLinkId: string, email: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authenticationControllerIsOwnerOfStepLink(stepLinkId, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlAttestationEssential} controlAttestationEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlAttestationControllerCreateEntity(controlAttestationEssential: ControlAttestationEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlAttestationControllerCreateEntity(controlAttestationEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlAttestationControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlAttestationControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlAttestationControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlAttestationControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlAttestationControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlAttestationControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlAttestationControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlAttestationControllerGetAllEntities(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlAttestationControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlAttestationControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlAttestationControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlAttestationControllerRemoveEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlAttestationIdEssential} controlAttestationIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlAttestationControllerUpdateEntity(controlAttestationIdEssential: ControlAttestationIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlAttestationControllerUpdateEntity(controlAttestationIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlAttestationDocumentEssential} controlAttestationDocumentEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlAttestationDocumentControllerCreateEntity(controlAttestationDocumentEssential: ControlAttestationDocumentEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlAttestationDocumentControllerCreateEntity(controlAttestationDocumentEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlAttestationDocumentControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlAttestationDocumentControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlAttestationDocumentControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlAttestationDocumentControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlAttestationDocumentControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlAttestationDocumentControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlAttestationDocumentIdEssential} controlAttestationDocumentIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlAttestationDocumentControllerUpdateEntity(controlAttestationDocumentIdEssential: ControlAttestationDocumentIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlAttestationDocumentControllerUpdateEntity(controlAttestationDocumentIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlAttestationStepRevisionEssential} controlAttestationStepRevisionEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlAttestationStepRevisionControllerCreateEntity(controlAttestationStepRevisionEssential: ControlAttestationStepRevisionEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlAttestationStepRevisionControllerCreateEntity(controlAttestationStepRevisionEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlAttestationStepRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlAttestationStepRevisionControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlAttestationStepRevisionControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlAttestationStepRevisionControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlAttestationStepRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlAttestationStepRevisionControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlAttestationStepRevisionIdEssential} controlAttestationStepRevisionIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlAttestationStepRevisionControllerUpdateEntity(controlAttestationStepRevisionIdEssential: ControlAttestationStepRevisionIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlAttestationStepRevisionControllerUpdateEntity(controlAttestationStepRevisionIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlControllerCreateEntity(body: object, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlControllerCreateEntity(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlIdEssential} controlIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlControllerUpdateEntity(controlIdEssential: ControlIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlControllerUpdateEntity(controlIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlRevisionEssential} controlRevisionEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerCreateEntity(controlRevisionEssential: ControlRevisionEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerCreateEntity(controlRevisionEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerGetAllEntities(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerRemoveEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlRevisionIdEssential} controlRevisionIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerUpdateEntity(controlRevisionIdEssential: ControlRevisionIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerUpdateEntity(controlRevisionIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlStepEssential} controlStepEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerCreateEntity(controlStepEssential: ControlStepEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerCreateEntity(controlStepEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerGetAllEntities(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} control 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerGetControlStepsByControl(control: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerGetControlStepsByControl(control, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} step 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerGetControlStepsByStep(step: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerGetControlStepsByStep(step, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerRemoveEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlStepIdEssential} controlStepIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerUpdateEntity(controlStepIdEssential: ControlStepIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerUpdateEntity(controlStepIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public coreTasksApiControllerImportAndSaveControls(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).coreTasksApiControllerImportAndSaveControls(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} zone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public coreTasksApiControllerImportAndSaveSteps(zone: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).coreTasksApiControllerImportAndSaveSteps(zone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} zone 
     * @param {string} spreadsheetName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public coreTasksApiControllerImportAndSaveStepsWithSpreadsheet(zone: string, spreadsheetName: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).coreTasksApiControllerImportAndSaveStepsWithSpreadsheet(zone, spreadsheetName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public coreTasksApiControllerPing(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).coreTasksApiControllerPing(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} to 
     * @param {string} subject 
     * @param {EmailParams} emailParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public emailControllerSendEmail(to: Array<string>, subject: string, emailParams: EmailParams, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).emailControllerSendEmail(to, subject, emailParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public employeeDataControllerGetAllEmployeeData(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).employeeDataControllerGetAllEmployeeData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public employeeDataControllerGetEmployee(email: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).employeeDataControllerGetEmployee(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public employeeDataControllerGetEmployeeLayout(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).employeeDataControllerGetEmployeeLayout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {string} level 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public graphControllerGetLayoutData(instant: string, level: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).graphControllerGetLayoutData(instant, level, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {LayoutBodyDTO} layoutBodyDTO 
     * @param {string} [algoType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public graphControllerGetNestedLayout(instant: string, layoutBodyDTO: LayoutBodyDTO, algoType?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).graphControllerGetNestedLayout(instant, layoutBodyDTO, algoType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {LayoutBodyDTO} layoutBodyDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public graphControllerGetRenderedEdges(instant: string, layoutBodyDTO: LayoutBodyDTO, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).graphControllerGetRenderedEdges(instant, layoutBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public jobAidControllerGetFromUrl(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).jobAidControllerGetFromUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepControllerCreateEntity(body: object, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepControllerCreateEntity(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepIdEssential} stepIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepControllerUpdateEntity(stepIdEssential: StepIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepControllerUpdateEntity(stepIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepDocumentEssential} stepDocumentEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepDocumentControllerCreateEntity(stepDocumentEssential: StepDocumentEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepDocumentControllerCreateEntity(stepDocumentEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepDocumentControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepDocumentControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepDocumentControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepDocumentControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepDocumentControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepDocumentControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepDocumentIdEssential} stepDocumentIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepDocumentControllerUpdateEntity(stepDocumentIdEssential: StepDocumentIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepDocumentControllerUpdateEntity(stepDocumentIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerCountTargets(instant: string, requestBody: Array<string>, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerCountTargets(instant, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepLinkEssential} stepLinkEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerCreateEntity(stepLinkEssential: StepLinkEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerCreateEntity(stepLinkEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerFindAllBySource(instant: string, body: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerFindAllBySource(instant, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerFindAllByTarget(instant: string, body: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerFindAllByTarget(instant, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerGetAllEntities(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerRemoveEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} source 
     * @param {string} target 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerRemoveStepLinksBySourceAndTarget(source: string, target: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerRemoveStepLinksBySourceAndTarget(source, target, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepLinkIdEssential} stepLinkIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerUpdateEntity(stepLinkIdEssential: StepLinkIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerUpdateEntity(stepLinkIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<StepRevision>} stepRevision 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerCreateBulkEntityForce(stepRevision: Array<StepRevision>, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerCreateBulkEntityForce(stepRevision, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepRevisionEssential} stepRevisionEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerCreateEntity(stepRevisionEssential: StepRevisionEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerCreateEntity(stepRevisionEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepRevision} stepRevision 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerCreateEntityForce(stepRevision: StepRevision, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerCreateEntityForce(stepRevision, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fieldName 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerFindAllDistinct(fieldName: string, instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerFindAllDistinct(fieldName, instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fieldName 
     * @param {FilterDTO} filterDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerFindAllDistinctGivenFilter(fieldName: string, filterDTO: FilterDTO, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerFindAllDistinctGivenFilter(fieldName, filterDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {FilterDTO} filterDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerFindAllGivenFilter(instant: string, filterDTO: FilterDTO, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerFindAllGivenFilter(instant, filterDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerFindAllSources(instant: string, body: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerFindAllSources(instant, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerFindAllTargets(instant: string, body: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerFindAllTargets(instant, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerFindByOwner(email: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerFindByOwner(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerFindByResponsibleUser(email: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerFindByResponsibleUser(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerFindByStepId(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerFindByStepId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} stepName 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerFindByStepName(stepName: string, instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerFindByStepName(stepName, instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<StepRevision>} stepRevision 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerFindNewValues(stepRevision: Array<StepRevision>, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerFindNewValues(stepRevision, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerGetAllEntities(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerGetUpdateAuthor(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerGetUpdateAuthor(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerRemoveEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepRevisionIdEssential} stepRevisionIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerUpdateEntity(stepRevisionIdEssential: StepRevisionIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerUpdateEntity(stepRevisionIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepRevision} stepRevision 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerUpdateEntityForce(stepRevision: StepRevision, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerUpdateEntityForce(stepRevision, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserIdEssential} userIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerCreateEntity(userIdEssential: UserIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerCreateEntity(userIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {User} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerUpdateUserRole(user: User, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerUpdateUserRole(user, options).then((request) => request(this.axios, this.basePath));
    }
}

